// combineReducers come from redux that used for combining reducers that we just made.
import { combineReducers } from 'redux'

// Reducers
import auth from './auth-reduser'
import user from "./user-reduser";
import sprint from './sprint-reducer'
import sprintStudents from './sprints-students-reducer'
import ftf from './ftf-reducer'
import ftfPairs from './ftf-pairs-reducer'
import homework from './homework-reducer'
import config from './configurator-reducer'
import students from './students-reducer'
import message from './message-reducer'
import common from './common-reduser'
import pagination from './pagination-reduser'
import preference from './preference-reduser'
import challenge from './challenge-reducer'

export default combineReducers({
    auth,
    user,
    message,
    sprint,
    sprintStudents,
    ftf,
    ftfPairs,
    homework,
    config,
    students,
    common,
    pagination,
    preference,
    challenge
})
