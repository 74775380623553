import {
    put,
    call,
    takeLatest,
    all
} from 'redux-saga/effects'

import {
    SET_HOMEWORK_CONFIG_ASSIGNMENT,
    SET_HOMEWORK_CONFIG_ASSIGNMENT_REQUESTED,
    SET_HOMEWORK_CONFIG_EDITOR_RAW_DATA_REQUESTED,
    SET_HOMEWORK_CONFIG_EDITOR_RAW_DATA,
    SET_HOMEWORK_CONFIG,
    SET_EMPTY_HOMEWORK_CONFIG,
    UPDATE_HOMEWORK_CONFIG,
    GET_CONFIG_BY_HOMEWORK,
    GET_CONFIG_BY_HOMEWORK_REQUEST,
    GET_CONFIG_BY_HOMEWORK_SUCCESS,
    GET_CONFIG_BY_HOMEWORK_ERROR,
    SET_HOMEWORK_CONFIG_MANY,
    CREATE_HOMEWORK_CONFIG,
    CREATE_HOMEWORK_CONFIG_REQUEST,
    CREATE_HOMEWORK_CONFIG_SUCCESS,
    CREATE_HOMEWORK_CONFIG_ERROR,
    UPDATE_HOMEWORK_CONFIG_REQUEST,
    UPDATE_HOMEWORK_CONFIG_SUCCESS,
    UPDATE_HOMEWORK_CONFIG_ERROR,
} from '../actions/configurator-actions'

import {
    getConfigByHomeworkId,
    createNewHomeworkConfig,
    updateExistHomeworkConfig
} from '../api/configurator-api'

function* getHomeworkConfig({ payload }) {

    yield put({ type: GET_CONFIG_BY_HOMEWORK_REQUEST })

    const response = yield call(getConfigByHomeworkId, payload)

    if(response.status === 200) {
        yield put({type: GET_CONFIG_BY_HOMEWORK_SUCCESS, payload: response.data })
    } else if(response.status === 404) {
        yield put({ type: SET_EMPTY_HOMEWORK_CONFIG, payload: response.data })
    } else {
        yield put({ type: GET_CONFIG_BY_HOMEWORK_ERROR })
    }
}

function* setHomeworkConfigMany({ payload }) {
    yield all(
        payload.map(
            (item) => put({ type: SET_HOMEWORK_CONFIG, payload: item })
        )
    );
}

function* setHomeworkConfigAssignment({ payload }) {
    yield put({ type: SET_HOMEWORK_CONFIG_ASSIGNMENT, payload: payload })
}

function* setHomeworkConfigTextEditor({ payload }) {
    yield put({ type: SET_HOMEWORK_CONFIG_EDITOR_RAW_DATA, payload: payload })
}

function* createHomeworkConfig({ payload }) {

    yield put({ type: CREATE_HOMEWORK_CONFIG_REQUEST })

    const response = yield call(createNewHomeworkConfig, payload)

    if(response.status === 200) {
        yield put({type: CREATE_HOMEWORK_CONFIG_SUCCESS, payload: response.data })
    } else {
        yield put({ type: CREATE_HOMEWORK_CONFIG_ERROR })
    }

}

function* updateHomeworkConfig({ payload }) {

    yield put({ type: UPDATE_HOMEWORK_CONFIG_REQUEST })

    const response = yield call(updateExistHomeworkConfig, payload)

    if(response.status === 200) {
        yield put({type: UPDATE_HOMEWORK_CONFIG_SUCCESS })
    } else {
        yield put({ type: UPDATE_HOMEWORK_CONFIG_ERROR })
    }
}

export default function* configSaga() {
    yield takeLatest(GET_CONFIG_BY_HOMEWORK, getHomeworkConfig)
    yield takeLatest(SET_HOMEWORK_CONFIG_MANY, setHomeworkConfigMany)
    yield takeLatest(SET_HOMEWORK_CONFIG_ASSIGNMENT_REQUESTED, setHomeworkConfigAssignment)
    yield takeLatest(SET_HOMEWORK_CONFIG_EDITOR_RAW_DATA_REQUESTED, setHomeworkConfigTextEditor)
    yield takeLatest(CREATE_HOMEWORK_CONFIG, createHomeworkConfig)
    yield takeLatest(UPDATE_HOMEWORK_CONFIG, updateHomeworkConfig)
}
