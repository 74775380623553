import { spawn } from 'redux-saga/effects'

// Sagas
import authSaga from "./auth-sage";
import userSaga from "./user-saga";
import sprintSaga from './sprint-saga';
import sprintStudentsSaga from './sprint-students-saga';
import ftfSaga from './ftf-saga'
import ftfPairsSaga from './ftf-pairs-saga'
import homeworkSaga from "./homework-saga";
import configSaga from "./configurator-saga";
import studentsSaga from "./students-saga"
import commonSaga from './common-sage';
import messageSaga from './message-sage'
import preferenceSaga from './preference-sage'
import challengeSaga from "./challenge-sage";

// Export the root saga
export default function* rootSaga() {
    yield spawn(authSaga)
    yield spawn(userSaga)
    yield spawn(sprintSaga)
    yield spawn(sprintStudentsSaga)
    yield spawn(ftfSaga)
    yield spawn(ftfPairsSaga)
    yield spawn(homeworkSaga)
    yield spawn(configSaga)
    yield spawn(studentsSaga)
    yield spawn(commonSaga)
    yield spawn(messageSaga)
    yield spawn(preferenceSaga)
    yield spawn(challengeSaga)
}
